import { createContext, useContext, useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation, makeVar, useReactiveVar } from '@apollo/client';
import Big from 'big.js';
import isNaN from 'lodash/isNaN';
import { useWillUnmount } from 'rooks';

import { parseNumber } from 'lib/formatters';

const GET_CAMPAIGN_CONTRIBUTE_CONTEXT = gql`
  query GetCampaignContributeContext(
    $campaignId: String!
    $fundraiserId: String
    $includeFundraiser: Boolean!
    $includeTeam: Boolean!
    $teamId: String
  ) {
    findCampaigns(id: $campaignId) {
      id
      donationFeeMode
      metricLabel
      metricLabelPlural
    }

    findFundraisers(id: $fundraiserId) @include(if: $includeFundraiser) {
      id
      performanceEstimate
      performanceSettings {
        metricLabel
        metricLabelPlural
      }
    }

    findTeams(id: $teamId) @include(if: $includeTeam) {
      id
      performanceSettings {
        metricLabel
        metricLabelPlural
      }
    }
  }
`;

const GET_CALCULATED_FEES = gql`
  mutation GetCalculatedFees($campaignId: String!, $amount: String!) {
    calculateDonationFees(campaignId: $campaignId, amount: $amount)
  }
`;

const CONTRIBUTION_EMPTY_VALUE = { type: 'donation' };

const CONTRIBUTION_VAR = makeVar(CONTRIBUTION_EMPTY_VALUE);

export const setContribution = (x) => CONTRIBUTION_VAR({ ...CONTRIBUTION_EMPTY_VALUE, ...x });

export const CampaignContributeContext = createContext();

const isInvalidAmount = (x) => isNaN(x) || x == null;

export const CampaignContributeProvider = ({ campaignId, children }) => {
  const [isCustomAmtDonated, setIsCustomAmtDonated] = useState(false);
  const contribution = useReactiveVar(CONTRIBUTION_VAR);

  const { data } = useQuery(GET_CAMPAIGN_CONTRIBUTE_CONTEXT, {
    variables: {
      campaignId,
      fundraiserId: contribution.fundraiserId,
      teamId: contribution.teamId,
      includeFundraiser: Boolean(contribution.fundraiserId),
      includeTeam: Boolean(contribution.teamId),
    },
  });

  const [calculateFees, calculateResult] = useMutation(GET_CALCULATED_FEES, {
    variables: { campaignId },
  });

  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const fundraiser = useMemo(() => data?.findFundraisers?.[0], [data]);
  const team = useMemo(() => data?.findTeams?.[0], [data]);

  const contributionAmount = parseNumber(contribution.amount);

  const estimatedPerformance = fundraiser?.performanceEstimate ?? 0;

  const estimatedSubtotal = useMemo(() => {
    if (isInvalidAmount(contributionAmount)) return null;

    const subtotal =
      contribution.type === 'pledge'
        ? Big(contributionAmount).times(estimatedPerformance)
        : Big(contributionAmount);

    return subtotal.toString();
  }, [contributionAmount, contribution.type, estimatedPerformance]);

  const estimatedFees = useMemo(
    () => (contribution.coverFee ? calculateResult.data?.calculateDonationFees : 0),
    [calculateResult.data, contribution.coverFee]
  );

  const estimatedTotal = useMemo(() => {
    if (!estimatedSubtotal) return estimatedSubtotal;

    const extra =
      parseNumber(campaign?.donationFeeMode === 'tip' ? contribution.tip : estimatedFees) || 0;

    return Big(estimatedSubtotal).plus(extra).toString();
  }, [campaign, estimatedSubtotal, estimatedFees, contribution]);

  const updateContribution = useCallback(
    (changes) => setContribution({ ...CONTRIBUTION_VAR(), ...changes }),
    []
  );

  const resetContribution = useCallback(() => setContribution(), []);

  const value = useMemo(
    () => ({
      campaignId,

      contribution,
      updateContribution,
      resetContribution,

      metricLabel:
        fundraiser?.performanceSettings?.metricLabel ??
        team?.performanceSettings?.metricLabel ??
        campaign?.metricLabel,
      metricLabelPlural:
        fundraiser?.performanceSettings?.metricLabelPlural ??
        team?.performanceSettings?.metricLabelPlural ??
        campaign?.metricLabelPlural,

      estimatedPerformance,
      estimatedSubtotal,
      estimatedFees,
      estimatedTotal,
      isCustomAmtDonated,
      setIsCustomAmtDonated,
    }),
    [
      campaignId,
      campaign,
      fundraiser,
      team,
      contribution,
      updateContribution,
      resetContribution,
      estimatedPerformance,
      estimatedSubtotal,
      estimatedFees,
      estimatedTotal,
      isCustomAmtDonated,
    ]
  );

  useEffect(() => {
    if (!estimatedSubtotal) return;
    calculateFees({
      variables: {
        amount: parseNumber(estimatedSubtotal).toString(),
      },
    });
  }, [estimatedSubtotal, calculateFees]);

  useWillUnmount(() => resetContribution());

  return (
    <CampaignContributeContext.Provider value={value}>
      {children}
    </CampaignContributeContext.Provider>
  );
};

CampaignContributeProvider.propTypes = {
  campaignId: PropTypes.string.isRequired,
  children: PropTypes.node,
};

CampaignContributeProvider.defaultProps = {
  children: null,
};

export const CampaignContributeConsumer = CampaignContributeContext.Consumer;

export const useCampaignContribute = () => useContext(CampaignContributeContext);
